<script setup lang="ts">
import type { PropType } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { notify } from "notiwind";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import type { User } from "~/model";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  user: {
    type: Object as PropType<User>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "user-updated"]);

const localUser = ref<User | null>(null);
const errors = ref({});

// Initialize localUser when props.user changes
watch(() => props.user, (newUser) => {
  if (newUser) {
    localUser.value = JSON.parse(JSON.stringify(newUser));
  }
}, { immediate: true });

dayjs.extend(relativeTime);

async function deleteUser() {
  if (!localUser.value) {
    return;
  }

  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete user ${localUser.value.email} ?`,
    message: "This action cannot be undone.",
  });

  if (!isCanceled) {
    try {
      await appStore.userStore.deleteUser(localUser.value);
      log.info(localUser.value);
      notify({
        group: "generic",
        title: "Success",
        text: "User deleted successfully",
      }, 1000);
      emit("user-updated");
    } catch (error) {
      notify({
        group: "error",
        title: "Error",
        text: "Failed to delete user",
      }, 3000);
    }
  }
  close();
}

async function updateUser() {
  if (!localUser.value) {
    return;
  }

  try {
    await appStore.userStore.updateUser(localUser.value);
    notify({
      group: "generic",
      title: "Success",
      text: "User updated successfully",
    }, 3000);
    emit("user-updated");
    close();
  } catch (error: any) {
    errors.value = error.response?.data?.errors || {};
    notify({
      group: "error",
      title: "Error",
      text: error.message || "Failed to update user",
    }, 3000);
  }
}

function close() {
  localUser.value = null; // Reset local user
  errors.value = {}; // Reset errors
  emit("update:modelValue", false);
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="flex-1">
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Edit User
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Change the details required and then choose Save Changes
                          </p>
                        </div>
                        <div class="flex h-7 items-center">
                          <button type="button" class="text-gray-400 hover:text-gray-500" @click="close">
                            <span class="sr-only">Close panel</span>
                            <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <label for="email" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                            Email Address
                          </label>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="email"
                              v-model="localUser.email"
                              name="email"
                              :disabled="true"
                            />
                          </div>
                          <label
                            for="firstName"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            First Name
                          </label>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="firstName"
                              v-model="localUser.firstName"
                              name="firstName"
                              :errors="errors"
                            />
                          </div>
                          <label
                            for="lastName"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            Last Name
                          </label>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="lastName"
                              v-model="localUser.lastName"
                              name="lastName"
                              :errors="errors"
                            />
                          </div>
                          <div>
                            <KodexaCheckbox
                              id="active"
                              v-model="localUser.active"
                              name="active"
                              label="Active"
                              :errors="errors"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="border-2 border-red-200 bg-white shadow sm:m-6 sm:mt-3 sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-6">
                          <h3 class="text-base font-semibold leading-6 text-red-600">
                            Delete User
                          </h3>
                          <div class="mt-2 items-center justify-center sm:flex">
                            Are you sure you want to delete this user? This cannot be undone.
                            <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
                              <KodexaButton id="deleteUser" type="danger" @click="deleteUser">
                                Delete User
                              </KodexaButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div class="flex justify-end space-x-3 pr-14">
                      <KodexaButton
                        type="secondary"
                        icon="cancel"
                        @click="close"
                      >
                        Cancel
                      </KodexaButton>
                      <KodexaButton
                        type="primary"
                        icon="content-save"
                        @click="updateUser"
                      >
                        Save Changes
                      </KodexaButton>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
